import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalConstant } from 'src/assets/info/constantes_globales';

@Component({
  selector: 'app-estados2',
  templateUrl: './estados2.component.html',
  styleUrls: ['./estados2.component.scss']
})
export class Estados2Component implements OnInit {
  detalleNacional:any={}
  consultaPoupular:any={}
  nulos:any;
  no:any
  // url = "./assets/info/detalleNacional.json";
  url = "./assets/info/Consulta_Popular_NACIONAL.json";  
  urlVotos = "./assets/info/votos.json";
  constructor(private http: HttpClient, public router:Router) { }

  ngOnInit(): void {

    this.http.get(this.url).subscribe((res) => {      
      this.consultaPoupular = res;      
      this.no = parseInt(this.consultaPoupular?.votacionPartidosConDistribucion[1]?.porcentaje) + parseInt(this.consultaPoupular?.votacionPartidosConDistribucion[0]?.porcentaje);
      this.nulos = parseInt(this.consultaPoupular?.votacionPartidosConDistribucion[2]?.porcentaje) + parseInt(this.consultaPoupular?.votacionPartidosConDistribucion[1]?.porcentaje) + parseInt(this.consultaPoupular?.votacionPartidosConDistribucion[0]?.porcentaje);    
    }); 
    
    // setTimeout(()=>{
    //   this.router.navigateByUrl('/espera');
    // }, GlobalConstant.timeOut);
  }

}
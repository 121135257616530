<div class="container-fluid">
    <div class="row pddng-t20">
        <div class="col-8 offset-2">
            <h2 class="titulo-opiniones">
                Opiniones
            </h2>
            <div class="row pb-3 pr-5">
                <div class="col-4 opiniones-si text-right pb-2">
                    <p class="circulo si">Sí</p>
                    <p class="votos">{{this.consultaPoupular?.votacionPartidosConDistribucion[0]?.total}}</p>
                    <p class="porcentaje">{{this.consultaPoupular?.votacionPartidosConDistribucion[0]?.porcentaje}}%</p>
                </div>
                <div class="col-4 text-right opiniones-no brdr-rl pb-2">
                    <p class="circulo no">No</p>
                    <p class="votos">{{this.consultaPoupular?.votacionPartidosConDistribucion[1]?.total}}</p>
                    <p class="porcentaje">{{this.consultaPoupular?.votacionPartidosConDistribucion[1]?.porcentaje}}%</p>
                </div>
                <div class="col-4 text-right opiniones-nulos pb-2">
                    <p class="circulo nulos">Nulos</p>
                    <p class="votos">{{this.consultaPoupular?.votacionPartidosConDistribucion[2]?.total}}</p>
                    <p class="porcentaje">{{this.consultaPoupular?.votacionPartidosConDistribucion[2]?.porcentaje}}%</p>
                </div>
            </div>
            <div class="contenedor-barra">
                <div class="barra-avance-nulos" [style.width.%]="this.nulos">
                </div>
                <div class="barra-avance-no" [style.width.%]="this.no">
                </div>
                <div class="barra-avance" [style.width.%]="this.consultaPoupular?.votacionPartidosConDistribucion[0]?.porcentaje">
                </div>
            </div>
            <div class="row">
                <div class="col-7">
                    <p class="total-opiniones text-center">
                        <strong>Total de opiniones</strong>
                    </p>
                </div>
                <div class="col-5 total-votos-opinion text-right">
                    <p class="votos clr-r">
                        <strong> {{this.consultaPoupular?.totalVotos}}</strong>
                    </p>
                    <p class="porcentaje">
                        100.0000%
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-4">
            <h1 class="titulo-seccion">
                Senaduría - <strong class="color-rosa">Nayarit</strong>
            </h1>    
        </div>
        <!-- <div class="col-md-4">
            <div class="row">
                <div class="col-6">
                    <h4><strong>Nulos</strong></h4>
                    <p>Total: <strong>{{_obtenerDatosService?.resumenVotacion?.nulos?.total}}</strong> </p>
                    <p>Porcentaje: <strong>{{_obtenerDatosService?.resumenVotacion?.nulos?.porcentaje}}%</strong></p>
                </div>
                <div class="col-6">
                    <h4><strong>No Registrados</strong></h4>
                    <p>Total: <strong>{{_obtenerDatosService?.resumenVotacion?.noRegistradas?.total}}</strong></p>
                    <p>Porcentaje: <strong>{{_obtenerDatosService?.resumenVotacion?.noRegistradas?.porcentaje}}%</strong></p>
                </div>
            </div>                                    
        </div> -->
        <div class="offset-4 col-md-4 text-right">
            <div class="votos-total">
                <p class="margin-cero px12">
                    <strong>Total de votos</strong>
                </p>
                <h1 class="color-rosa margin-cero">
                    <strong>
                        {{_obtenerDatosService?.totalVotos}}
                    </strong>                
                </h1>            
                <p class="margin-cero color-rosa">
                    <strong>100.0000% </strong>               
                </p>
            </div>            
        </div>
    </div>
    <div class="table-responsive">
        <table class="table table-borderedHeader" id="t-g-vpp">
            <thead class="table-borderedHeader ">
                <tr>
                    <td class="noBorde"></td>
                    <td class="noBorde fondoGrafica td-relative" *ngFor="let item of _obtenerDatosService?.votos"></td>
                </tr>
                <tr>
                    <td class="td-relative table-borderedHeader sinBorde">
                        <!-- <div class="linea-chart-nl line-chart-100 line-char-first ">{{ (_obtenerDatosService?.totalProporcional | stringtoint) | formatmiles }}</div>
                        <div class="linea-chart-nl line-chart-80 line-char-first ">{{ (((_obtenerDatosService?.totalProporcional | stringtoint) * .8) | round) |
                            formatmiles }}
                        </div>
                        <div class="linea-chart-nl line-chart-60 line-char-first ">{{ (((_obtenerDatosService?.totalProporcional | stringtoint) * .6) | round) |
                            formatmiles }}
                        </div>
                        <div class="linea-chart-nl line-chart-40 line-char-first ">{{ (((_obtenerDatosService?.totalProporcional | stringtoint) * .4) | round) |
                            formatmiles }}
                        </div>
                        <div class="linea-chart-nl line-chart-20 line-char-first ">{{ (((_obtenerDatosService?.totalProporcional | stringtoint) * .2) | round) |
                            formatmiles }}
                        </div>
                        <div class="linea-chart-nl line-chart-0 line-char-first ">0</div>
                        <div class="my-chart ">
                            <div style="height: 0px; "></div>
                        </div> -->
                    </td>                    
                    <td class="fondoGrafica td-relative" [ngClass]="{ 'filaGanador superior-ganador' : itemPartido?.ganador, 'bg-gris' : itemPartido.partido == 61 || itemPartido.partido == 62  }" *ngFor="let itemPartido of _obtenerDatosService?.votos">
                        <div class="linea-chart line-chart-100 "></div>
                        <div class="linea-chart line-chart-80 "></div>
                        <div class="linea-chart line-chart-60 "></div>
                        <div class="linea-chart line-chart-40 "></div>
                        <div class="linea-chart line-chart-20 "></div>
                        <div class="linea-chart-nl line-chart-0 "></div>
                        <div class="progress-bar-vertical">
                            <div aria-valuemax="100" aria-valuemin="0" class="progress-bar progres-chart " placement="top" role="progressbar" [attr.title]="" hide-delay="50" 
                                [ngStyle]="{ 'height': itemPartido.porcentajeProporcional+'%' ,'background-color': diccionarioPartidos[itemPartido.partido]?.colorPartido}">
                            </div>
                        </div>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="thtitulos">  
                        <span class="votos">
                            <strong>Votos</strong>
                        </span>    
                        <br>
                        <span class="px12">
                            <strong>En la Entidad</strong>
                        </span>                                          
                    </td>
                    <td class="text-center" [ngClass]="{filaGanador: data?.ganador, 'bg-gris' : data.partido == 61 || data.partido == 62  }" *ngFor="let data of _obtenerDatosService?.votos">                        
                        <ng-container *ngIf="data?.partido != 61 && data.partido != 62">
                            <div>
                                <img class="imagen-candidato" src="assets/res/{{data?.urlImagen}}" />
                            </div>
                            <div>                                                                                          
                                <p class="px12 mb-0 mt-3">{{data.candidatoPropietario}}</p>
                                <hr class="m-0">
                                <p class="px12">{{data.candidatoSuplente}}</p>
                            </div>
                            <div>
                                <img class="img-partido" [src]="pathImg+diccionarioPartidos[data.partido]?.imgPartido " />
                            </div>                     
                        </ng-container>
                        <ng-container *ngIf="data?.partido == 61">                           
                            <div class="no-registrados">              
                                <h4 class="mt-4 mb-4">Candidaturas No Registradas</h4>                                                                                                            
                            </div>
                        </ng-container>
                        <ng-container *ngIf="data?.partido == 62">                            
                            <div class="nulos">              
                                <h4 class="mt-4 mb-4">Nulos</h4>                                                                                                            
                            </div>
                        </ng-container>
                        <ng-container>                                   
                            <div>                                
                                <span class="numero-votos">
                                    {{data.votosEntidad}}
                                </span>
                            </div>
                        </ng-container> 

                    </td>
                </tr>                               
                <tr class="border3-t-rosaINE ">
                    <td class="bg-gris-3">
                        <span class="px12">
                            <strong>En el Extranjero</strong>
                        </span>
                    </td>
                    <td [ngClass]="{'filaGanador': totales?.ganador , 'bg-gris' : totales.partido == 61 || totales.partido == 62  }" *ngFor="let totales of _obtenerDatosService?.votos" class="text-center bg-rosa2">
                        <span class="numero-votos">
                          {{totales.votosExtranjero}}
                      </span>
                    </td>
                </tr>
                <tr>
                    <td class="thtitulos">                        
                        <strong class="px16">
                            Total
                        </strong>
                    </td>
                    <td class="text-center" [ngClass]="{'filaGanador rosaIne' : itemPartido?.ganador, 'bg-gris' : itemPartido.partido == 61 || itemPartido.partido == 62  }" *ngFor="let itemPartido of _obtenerDatosService?.votos">
                        <ng-container>                            
                            <div>
                                <span class="numero-votos">
                                    {{itemPartido.votos}}
                                </span>
                            </div>
                        </ng-container>

                    </td>
                </tr>
                <tr class="border3-t-rosaINE ">
                    <td class="bg-gris-3">
                        <span class="px12">
                        Porcentaje
                    </span>
                    </td>
                    <td [ngClass]="{'filaGanador inferior-ganador': totales?.ganador, 'bg-gris' : totales.partido == 61 || totales.partido == 62  }" *ngFor="let totales of _obtenerDatosService?.votos" class="text-center bg-rosa2">
                        <strong>
                            <span class="px12">
                                {{totales.porcentaje}}%
                              </span>
                        </strong>                        
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
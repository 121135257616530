import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalConstant } from 'src/assets/info/constantes_globales';

@Component({
  selector: 'app-estados1',
  templateUrl: './estados1.component.html',
  styleUrls: ['./estados1.component.scss']
})
export class Estados1Component implements OnInit {
  detalleNacional:any={}
  datosNacional:any={}
  _obtenerDatosService:any={};
  diccionarioPartidos:any={};
  urlAvance = "./assets/info/avanceNacional.json";
  pathImg:string='./assets/img/partidospoliticos/';  
  url = "./assets/info/votos_candidatura.json";
  url2 = "./assets/info/diccionarioPartidos.json";
  constructor(private http: HttpClient, public router:Router) { }

  ngOnInit(): void {
    this.http.get(this.url).subscribe((res) => {      
      this._obtenerDatosService = res;
    });
    this.http.get(this.url2).subscribe((res) => {      
      this.diccionarioPartidos = res;      
    });

    
    if (this.datosNacional.paginaInicial === false){
      setTimeout(()=>{
        this.router.navigateByUrl('/nayarit');
    }, GlobalConstant.timeOut);
    }
    if (this.datosNacional.paginaInicial === true){
      setTimeout(()=>{
          this.router.navigateByUrl('/espera');
      }, GlobalConstant.timeOut);
    }
     
  }

}

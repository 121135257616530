import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalConstant } from 'src/assets/info/constantes_globales';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {
  constructor(public router:Router, private http: HttpClient) { }

  urlAvance = "./assets/info/avanceNacional.json";
  detalleNacional:any={};
  datosNacional:any={};
  horaInicial: any;

  ngOnInit(): void{

    this.http.get(this.urlAvance).subscribe((res) => {
      this.datosNacional=res;

      if (this.datosNacional.paginaInicial === false){
            setTimeout(()=>{
              this.router.navigateByUrl('/nayarit');
          }, GlobalConstant.timeOut);
      }
      if (this.datosNacional.paginaInicial === true){
        setTimeout(()=>{
            this.router.navigateByUrl('/espera');
        }, GlobalConstant.timeOut);
      }
    });    
  }

}

<div class="container-fluid backg-1">

    <div class="row">
        <div class="col-2 d-flex justify-content-center">
            <img src="./assets/img/inicio/logoINE.svg" alt="Logo Instituto Nacional Electoral" width=50%>
        </div>
        <div class="col-8  text-center">
            <h1 class="margin-0">Programa de Resultados <span class="rosaIne">E</span>lectorales Preliminares 2021</h1>
            <h3 class="margin-0">Elección Extraordinaria | Senaduría Nayarit</h3>
        </div>
        <div class="col-2 d-flex justify-content-center">
            <img src="./assets/img/inicio/Logo-PREP-Nayarit.svg" alt="Logo Instituto Nacional Electoral" width=50%>
        </div>
    </div>
</div>
<div class="container-fluid backg-2">
    <div class="row plc-tm">
        <div class="col-1 text-center">
            <h3 class="robo-bold avance-nacional pddng-t10">
                Avance
            </h3>
        </div>
        <div class="col-2">
            <div class="row">
                <div class="col-3">
                    <p class="subtitle mt-2">
                        <span class="robo-bold tamanio-computadas">Actas capturadas  </span>                        
                    </p>
                </div>
                <div class="col-9 text-right">
                    <p class="numero-actas mt-3">
                        <span class="robo-bold">{{datos?.capturadas?.total}}</span>
                        <span class="robo-reg"> de {{datos?.capturadas?.esperadas}}</span>
                    </p>                    
                </div>                
            </div>
        </div>
        <div class="col-2">            
            <div class="text-right">
                <p class="margin-0 px22">({{datos?.capturadas?.porcentaje}}%)</p>
            </div>
            <div class="border-barra-1">
                <div class="barra-avance" [style.width.%]="datos?.capturadas?.porcentaje"></div>
            </div>                            
        </div>
        <div class="col-3">           
            <p class="mt-3 text-center">
                <strong>
                    <span class="participacion">Participación Ciudadana</span> <span class="porcentaje"> {{datos?.participacionCiudadana?.porcentaje}}%</span>
                </strong>
            </p>                                    
        </div>
        <div class="col-4">
            <div class="row">
                <div class="col-8 fecha">
                    <p class="mt-3">
                       <span class="ultimo">Último corte</span>  <span class="datos"> {{datos?.ultimoCorte?.fecha}}</span> 
                    </p>                            
                </div>                
                <div class="col-4 borde-izq">
                    <div class="text-right margen-der">
                        <p class="tiempo-centro margin-0">Tiempo del Centro</p>
                        <p class="utc px22 "><span class="fw-700">{{datos?.ultimoCorte?.hora}} h</span> UTC-6</p>
                    </div>                    
                </div>

            </div>
        </div>
    </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'pagina-prensa';
  public timeOut = 3000;
  horaInicial: any;
  constructor(public router: Router, private http: HttpClient){

  }

  ngOnInit(): void{



    this.http.get('./assets/info/refresh.json').subscribe(res => {
      this.horaInicial = res;
      setInterval(() => {
          this.http.get('./assets/info/refresh.json').subscribe(res => {
              if (this.horaInicial.hora === res["hora"]) {
              }else{
                  window.location.reload();
              }                    
          })                
      }, 10000);
    });
  }
  

}



<div class="fondo-general">
    <div class="container-fluid">
        <div class="row">
            <div class="col-8 offset-2">                
                <div class="text-center logo-ine">
                    <img src="./assets/img/inicio/fondo-ine-nayarit.svg" alt="logo INE">                    
                </div>                            
                <div class="text-center titulo-inicio">
                    <h2>Elección Extraordinaria <br> | Senaduría Nayarit</h2>
                </div>
                <div class="text-center descripcion mt-4">
                    <h3>
                        5 de diciembre de 2021 a partir de<br> las 20:00 horas, Tiempo del Centro (UTC-6)
                    </h3>
                </div>
            </div>
        </div>
    </div>
</div>
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Estados1Component } from './components/estados1/estados1.component';
import { Estados2Component } from './components/estados2/estados2.component';
import { InicioComponent } from './components/inicio/inicio.component';


const routes: Routes = [
  {path:'',
  redirectTo:'espera',
  pathMatch:'full'},
{
  path:'espera',
  component: InicioComponent
},
{
  path: 'nayarit',
  component: Estados1Component
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

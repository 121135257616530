import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { HeaderComponent } from './components/header/header.component';
import {HttpClientModule} from '@angular/common/http';
import { HighchartsChartModule} from 'highcharts-angular';
import { ChartModule } from 'angular-highcharts';
import { Estados1Component } from './components/estados1/estados1.component';
import { Estados2Component } from './components/estados2/estados2.component';
import { TituloPipe } from './pipes/titulo/titulo.pipe';
import { StringtointPipe } from './pipes/stringtoint.pipe';
import { FormatmilesPipe } from './pipes/formatmiles.pipe';
import { RoundPipe } from './pipes/round.pipe';



@NgModule({
  declarations: [
    TituloPipe,
    StringtointPipe,
    FormatmilesPipe,
    RoundPipe,
    AppComponent,
    InicioComponent,
    HeaderComponent,    
    Estados1Component,
    Estados2Component,    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HighchartsChartModule,
    ChartModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
